import { Dto } from "@open/sdk";
import { NEXT_PUBLIC_HEMIDAL_URL as baseUrl } from "data/base-url";
import { serverApiClient } from "data/instance";
import { verify } from "jsonwebtoken";
import { NextAuthOptions, User } from "next-auth";
import AzureADProvider from "next-auth/providers/azure-ad";
import CredentialsProvider from "next-auth/providers/credentials";
import GithubProvider from "next-auth/providers/github";
import GoogleProvider from "next-auth/providers/google";
import HubspotProvider from "next-auth/providers/hubspot";
import { AuthProviderU, OAuth2ProviderU } from "./auth.types";
import { IS_DEV } from "./env";
import {
  AzureADProviderStrategy,
  GitHubProviderStrategy,
  GoogleProviderStrategy,
  HubspotProviderStrategy,
  OAuthUser,
  getOAuthUser,
} from "./oauth.strategy";
import { SlackService } from "./slack.service";

const personalEmailProviders = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
  "protonmail.com",
  "zoho.com",
  "mail.com",
  "live.com",
  "outlook.com",
  "msn.com",
  "outlook.my",
  "outlook.com.my",
  "hotmail.my",
];

function isBusinessEmail(email: string) {
  const emailDomain = email.split("@").at(-1)?.trim();

  if (!emailDomain) return false;

  return !personalEmailProviders.includes(emailDomain.toLowerCase());
}

const getAccessToken = async (body: Dto["GetUserSessionRequestDto"]) => {
  const { data } = await serverApiClient.POST("/backend/user/session", {
    body,
  });
  return data;
};

async function loginOrRegister(body: Dto["CreateUserDto"]) {
  return serverApiClient.POST("/backend/user/marhaba", { body });
}
const doesEmailExistInOurDataFuckinBase = (
  body: Dto["DoesEmailExistInOurDatabaseRequestDto"],
) => {
  return serverApiClient.POST("/backend/user/findEmail", { body });
};

// The problems
// 1- Disable registration for new users.
// 2- Auto login users with email and password and invitation token.
export const authOptions: NextAuthOptions = {
  providers: [
    GithubProvider({
      name: "Github",
      id: "github" satisfies OAuth2ProviderU,
      clientId: process.env.GITHUB_ID ?? "",
      clientSecret: process.env.GITHUB_SECRET ?? "",
      // @ts-ignore
      scope: "read:user",
    }),
    HubspotProvider({
      name: "HubSpot",
      id: "hubspot" satisfies OAuth2ProviderU,
      clientId: process.env.HUBSPOT_CLIENT_ID ?? "",
      clientSecret: process.env.HUBSPOT_CLIENT_SECRET ?? "",
    }),
    GoogleProvider({
      name: "Google",
      id: "google" satisfies OAuth2ProviderU,
      clientId: process.env.GOOGLE_ID ?? "",
      clientSecret: process.env.GOOGLE_SECRET ?? "",
    }),
    AzureADProvider({
      id: "azure-ad" satisfies OAuth2ProviderU,
      clientId: process.env.AZURE_AD_CLIENT_ID ?? "",
      clientSecret: process.env.AZURE_AD_CLIENT_SECRET ?? "",
      tenantId: process.env.AZURE_AD_TENANT_ID ?? "common",
      authorization: {
        params: {
          scope: "openid profile email",
        },
      },
    }),
    CredentialsProvider({
      id: "test-basic-auth" satisfies AuthProviderU,
      name: "Test Basic Auth",
      credentials: {
        email: {
          label: "Email",
          type: "text",
          placeholder: "test@example.com",
        },
        password: { label: "Password", type: "password" },
      },
      async authorize(credentials) {
        if (!credentials) return null;

        try {
          // Validate credentials against your backend
          const { data } = await serverApiClient.POST(
            "/backend/user/test-user-login",
            {
              body: {
                email: credentials.email,
                password: credentials.password,
              },
            },
          );

          if (data && data.id) {
            return {
              id: data.id.toString(),
              email: data.email,
              name: data.name || "Test User",
            };
          }

          return null;
        } catch (error) {
          console.error("Test auth error:", error);
          return null;
        }
      },
    }),
    CredentialsProvider({
      id: "credentials-invitation" satisfies AuthProviderU,
      name: "Credentials",
      credentials: {
        email: { label: "Email", type: "text", placeholder: "Email" },
        token: { label: "Token", type: "text" },
        name: { label: "Name", type: "text" },
      },
      async authorize(credentials) {
        if (!credentials) return null;
        const request = await fetch(`${baseUrl}/invitation/accept`, {
          method: "POST",
          body: JSON.stringify({
            ...credentials,
            password: "",
          }),
          headers: { "Content-Type": "application/json" },
        });
        const response = await request.json();
        if (response.email) {
          return {
            email: response.email,
            id: response.id,
            name: response.name,
          };
        }
        return null;
      },
    }),
    CredentialsProvider({
      id: "switch-org" satisfies AuthProviderU,
      name: "Switch Organization",
      credentials: {
        orgId: {
          label: "Organization ID",
          type: "text",
          placeholder: "Organization ID",
        },
      },
      async authorize(credentials) {
        if (!credentials) return null;

        const { data } = await serverApiClient.GET(
          "/backend/user/switch-org/{org_id}",
          {
            params: { path: { org_id: credentials.orgId } },
          },
        );

        if (data) {
          return {
            id: data.id.toString(),
            email: data.email,
            orgId: data.org_id,
          } satisfies User;
        }
        return null;
      },
    }),
  ],
  secret: process.env.SECRET,
  session: {
    strategy: "jwt",
  },
  jwt: {
    secret: process.env.SECRET,
  },
  pages: {
    signIn: "/auth", // Displays signin buttons
    signOut: "/",
    newUser: "/auth", // If set, new users will be directed here on first sign in
    error: "/auth",
    // verifyRequest: '/auth/verify-request', // Used for check email page
  },
  callbacks: {
    async signIn({ user, account, profile, credentials }) {
      if (account?.provider === "switch-org") {
        if (user) return true;
      }

      if (credentials) {
        if ("from" in credentials) {
          return true;
        }
        if (!user.email || !user.name) return false;
        try {
          const { data } = await loginOrRegister({
            email: user.email,
            name: user.name,
            avatar_url: user.image ?? undefined,
            meta: { skills: [] },
          });
          return !!data;
        } catch (e) {
          console.error(e);
          return false;
        }
      }

      let _user: OAuthUser | null = null;

      switch (account?.provider) {
        case "github":
          _user = getOAuthUser(
            profile,
            new GitHubProviderStrategy(),
          );
          break;
        case "google":
          _user = getOAuthUser(
            profile,
            new GoogleProviderStrategy(),
          );
          break;
        case "hubspot":
          _user = getOAuthUser(profile, new HubspotProviderStrategy());
          break;
        case "azure-ad":
          _user = getOAuthUser(profile, new AzureADProviderStrategy());
          break;
        default:
          break;
      }
      console.log("user", _user);
      if (!_user) {
        return false;
      }

      const disableRegistration =
        process.env.NEXT_PUBLIC_DISABLE_REGISTERATION === "true";
      const { data } = await doesEmailExistInOurDataFuckinBase({
        email: _user.email,
      });

      if (data && data.error?.status === 404) {
        const isBusiness = isBusinessEmail(_user.email);

        if (disableRegistration) {
          await SlackService.sendSlackNotification(
            `a user with email ${_user.email} tried to register for the first time.`,
          );
          return "https://cal.com/opencopilot/30min";
        }

        if (!isBusiness && !IS_DEV) {
          await SlackService.sendSlackNotification(
            `a user with email ${_user.email} tried to register without a business email address.`,
          );
          throw new Error("A business email address is required");
        }
      }

      try {
        const { data } = await loginOrRegister({
          email: _user.email,
          name: _user.name,
          avatar_url: _user.avatar_url ?? undefined,
          meta: { skills: [] },
        });
        return !!data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    /**
     * @description
     * The private encoded/decoded token
     * To make anything publicly available in the client, use the `session` callback to pass data from `token` to `session`.
     */
    async jwt({ token, user, account }) {
      // `user` argument is available only for the `signIn` callback
      // `token` argument will be available every time this callback is invoked

      if (user && user.email) {
        const data = await getAccessToken({
          email: user.email,
          orgId: user.orgId,
        });
        token.email = user.email;
        token.access_token = data?.access_token;
        token.picture = user.image || data?.avatar_url;
        token.orgId = user.orgId || data?.org_id;
      }

      if (!token.orgId && token.access_token && process.env.JWT_SECRET) {
        const decoded = verify(token.access_token, process.env.JWT_SECRET)
          .sub as unknown as Dto["JwtUserDto"];
        token.orgId = decoded.org_id;
      }

      return token;
    },

    /**
     * @description
     * The public session object (subset of the private token)
     */
    async session({ session, token }) {
      if (!session.user) {
        session.user = {};
      }

      if (session.user) {
        session.access_token = token.access_token;
        session.user.email = token.email ?? session.user.email;
        session.user.orgId = token.orgId ?? session.user.orgId;
        session.user.name = token.name ?? session.user.name;
        session.user.image = token.picture ?? session.user.image;
      }

      return session;
    },
  },

  events: {},
  debug: true,
};
