import createClient, {
  MiddlewareOnError,
  type Middleware,
  wrapAsPathBasedClient,
} from "openapi-fetch";
import type { paths } from "./schema";
import type { components } from "./schema";

type Options = {
  baseUrl: string;
  onRequest?: Middleware["onRequest"];
  onResponse?: Middleware["onResponse"];
  onError?: MiddlewareOnError;
};

const noop = () => {
  // noop
};

const basicClient = (options: Options) => {
  let client = createClient<paths>({
    baseUrl: options.baseUrl,
  });

  let middlewares: Middleware = {
    onRequest: options.onRequest,
    onResponse: options.onResponse,
    onError: options.onError ?? noop,
  };

  client.use(middlewares);
  return client;
};

const pathBasedClient = (client: ReturnType<typeof basicClient>) => {
  return wrapAsPathBasedClient(client);
};
export { pathBasedClient };

export type { components, operations, paths } from "./schema";
export type Dto = components["schemas"];
export default basicClient;
