interface HubSpotProfile extends Record<string, any> {
  // TODO: figure out additional fields, for now using
  // https://legacydocs.hubspot.com/docs/methods/oauth2/get-access-token-information

  user: string;
  user_id: string;

  hub_domain: string;
  hub_id: string;
}
import { AzureADProfile } from "next-auth/providers/azure-ad";
import { GithubProfile } from "next-auth/providers/github";
import { GoogleProfile } from "next-auth/providers/google";
export interface OAuthUser {
  name: string;
  email: string;
  avatar_url?: string;
  provider: string;
}

interface OAuthProvider<T = any> {
  getUser(profile: T): OAuthUser;
}

export class GitHubProviderStrategy implements OAuthProvider<GithubProfile> {
  getUser(profile: GithubProfile): OAuthUser {
    return {
      name: profile.user.name,
      email: profile.user.email,
      provider: "github",
      avatar_url: profile.avatar_url,
    };
  }
}

export class GoogleProviderStrategy implements OAuthProvider<GoogleProfile> {
  getUser(profile: GoogleProfile): OAuthUser {
    return {
      name: profile.name,
      email: profile.email,
      provider: "google",
      avatar_url: profile.picture,
    };
  }
}
export class HubspotProviderStrategy implements OAuthProvider<HubSpotProfile> {
  getUser(profile: HubSpotProfile): OAuthUser {
    return {
      provider: "hubspot",
      name: profile.user,
      email: profile.user,
      avatar_url: "",
    };
  }
}

export class AzureADProviderStrategy implements OAuthProvider<AzureADProfile> {
  getUser(profile: AzureADProfile): OAuthUser {
    return {
      name: profile.name,
      email: profile.email,
      avatar_url: profile.picture,
      provider: "azure-ad",
    };
  }
}

export function getOAuthUser(profile: any, provider: OAuthProvider): OAuthUser {
  return provider.getUser(profile);
}
